/* src/components/InfoCard.css */
.info-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    background: rgba(255, 255, 255);
    /*background: rgba(255, 255, 255, 0.9);*/
    border-radius: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.1s ease-out;
    width: 240px;
    height: 175px;
    padding:15px;
    gap:15px;
}

div.info-card.displaced {
    margin-left: -80%;    
}

.icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #007bff;
    border-radius: 50%;
    color: #fff;
}

.text {
    font-family: "Titillium Web";
    color:#67697A;
    font-size:16px;
    font-weight:normal;
    flex-direction: column;
    display: flex;
}
