/* Root Level definitions */

/* Estilos para desktop */

@media (min-width: 1923px) {
	
	html {
	  font-size: 110%;
	}
		
}

@media (min-width: 1024px) and (max-width: 1920px) {
	
	html {
	  font-size: 100%;
	}
			
}

@media (min-width: 1024px) and (max-width: 1520px) {
	
	html {
	  font-size: 100%;
	}

		
}

@media (min-width: 1025px) and (max-width: 1130px) {
	
	html {
	  font-size: 90%;
	}

		
}

/* Only 1024 */
@media (width: 1024px) {
	
	html {
	  font-size: 90%;
	}
			
}

/* Estilos para tablet */
@media (min-width: 600px) and (max-width: 1023px) {
	html {
	  font-size: 100%;
	}
	
}

/* Estilos para mobile */
@media (max-width: 600px) {
	html {
	  font-size: 90%;
	}
	
	
}

.registerModalIntro {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 120%;
	text-align: left;
	letter-spacing: 0.1em;
	color:#6B6B6B;
	margin-bottom:10px;
	margin-top: 15px;
}

.p-password-input {
	width:100%;
}

.p-dialog .p-dialog-footer {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem !important;
}

.terms h1 {
	font-size: 2rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.terms h2 {
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 0.75rem;
}

.terms p {
    margin-bottom: 1rem;
}

.modalFooterButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;	
}

.modalFooterButtonsEnd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;	
}

.modalFooterButtonsLeft {
	flex: 2;
	text-align: start;	
}

.modalFooterButtonsRight {
	flex: 1.5;
}

.requiredField {
	color:red;
}

.contactUsQuestion .p-panel-header {
	border:0 !important;
}

.contactUsQuestion .p-panel-header .p-panel-title {
	color: #67697A;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 1.3rem */
	text-align:left;
}

.contactUsQuestion .p-panel-header .p-panel-icons .p-panel-header-icon {
	background-color:#00A3FF;
	color:#FFF;
}

.contactUsQuestion .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #FFF;
    border-color: rgba(0, 0, 0, 0);
    background: #00A3FF;
}

.contactUsQuestion .p-toggleable-content .p-panel-content {
	padding:1rem !important;
	padding-left:1.5rem !important;
	padding-right:1.5rem !important;
	text-align:left;
	border-top:1px solid #dee2e6 !important;
}

.faqButton {
	max-width: 14.9375rem; 
	min-width: 9.9375rem; 
	height:auto;
	background-color: #fff; 
	border-radius: 24px;
	display:flex;
	flex-direction:column;
	gap:1.6rem;
	padding:2rem;
	cursor:pointer;
}

.faqButtonTitle {
	color: #67697A;
	font-family: "Titillium Web";
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	line-height: 2.12938rem; 
	text-align:left;
}

.faqButtonIcon {
	width:40px;
	height:40px;
	border-radius:100%;
	background-color:#814DE5;
	display:flex;
	align-items:center;
	justify-content: center;
}

.faqButton.selected {
	background-color: #00A3FF; 
}

.faqButtonTitle.selected {
	color: #FFF;
}

.faqButtonIcon.selected {
	background-color:#FFF;
}

