
.user-card-select {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    border-radius: 99px;
    height:40px;
    margin-right:8px;
  }
  
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .company-info {
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items:center;
    gap:10px;
    margin:15px;
    margin-right:20px;
  }

  .user-info {
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items:center;    
    gap:10px;
    margin:15px;
    margin-right:20px;
  }
 
.select-agency-label {
    color: #814DE5;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
    letter-spacing: 1.4px;    
}

  .company-name, .user-name {
    display: block;
    font-size: 14px;
    color: #333;
    max-width: 185px;
    text-align:left;
    white-space: nowrap;                  
    overflow: hidden; /* "overflow" value must be different from "visible" */
    text-overflow:    ellipsis;        
  }
  
  .company-name {
    font-weight: bold;
  }
  
  .dropdown-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E6E6FA;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-right:20px;
  }

  .mousePointer {
     cursor:pointer;  
  }
  