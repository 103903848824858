/* Root Level definitions */

/* Estilos para desktop */

@media (min-width: 1923px) {
	
	html {
	  font-size: 110%;
	}
		
}

@media (min-width: 1024px) and (max-width: 1920px) {
	
	html {
	  font-size: 100%;
	}
	
	/* Login Section Form Wrapper */
	.login__wrapper_form_wrapper {
	  height: 30vw;
	}

	/* Login Section Concept Image Wrapper */
	.login__wrapper_conceptImage_wrapper {
	  height: 30vw;
	}
		
}

@media (min-width: 1024px) and (max-width: 1520px) {
	
	html {
	  font-size: 100%;
	}

		
}

@media (min-width: 1025px) and (max-width: 1130px) {
	
	html {
	  font-size: 90%;
	}

		
}

/* Only 1024 */
@media (width: 1024px) {
	
	html {
	  font-size: 90%;
	}
	
	.conceptImage {
		top:15% !important;		
		max-width:520px !important;
		left:-60px !important;
	}	

	.textBlockMargin {
		padding-left: 10px;
		padding-right: 10px;
	}
			
		
}

/* Estilos para tablet */
@media (min-width: 600px) and (max-width: 1023px) {
	html {
	  font-size: 100%;
	}
	
	#contatoSection {
		padding-bottom: 40px !important;
	}
	
	.textBlockMargin {
		margin-top:50px;
		height:unset !important;
	}
	
	.wrapper__content_container.mobile {
		background:unset !important;
	}	
			

	.contatoSection__wrapper_content_wrapper.mobile div.tAlignL {
		text-align:center !important;
	}
	
	.login__form_wrapper {
		margin-top: 2.5rem !important;
	}					
	
	.login__wrapper_form_wrapper_cornerImage, .login__wrapper_conceptImage_wrapper_cornerImage {
		display:none;
	}
	
	.login__form_wrapper div {
		margin-right:unset !important;
	}
	
	.login__wrapper_conceptImage_wrapper {
		min-height: unset !important;
	}	

}

/* Estilos para mobile */
@media (max-width: 600px) {
	html {
	  font-size: 90%;
	}
	
	.textBlockMargin {
		padding-left: 30px;
		padding-right: 30px;
		height:unset !important;
	}


	.wrapper__content_container.mobile {
		background:unset !important;
	}
		
	.login__wrapper.mobile img {
		max-width: 310px !important;
	}

	.contatoSection__wrapper_content_wrapper.mobile div.tAlignL {
		text-align:center !important;
	}
	
	.login__form_wrapper {
		margin-top: 2.5rem !important;
		padding-left:1rem !important;
		padding-right:1rem !important;
	}					
	
	.login__wrapper_form_wrapper_cornerImage, .login__wrapper_conceptImage_wrapper_cornerImage {
		display:none;
	}
	
	.login__form_wrapper div {
		margin-right:unset !important;
	}
	
	.login__wrapper_conceptImage_wrapper {
		min-height: unset !important;
	}		
	
	
}

/* Login Section Wrapper */
.login__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  background-color:#F2F4F7;
  border-radius:30px;
}

/* Login Section Wrapper */
.login__wrapper.mobile {
  flex-direction: column !important;
    margin-top: 0px !important;
}


/* Login Section Wrapper */
.login__wrapper.mobile img {
	margin-right:unset !important;
	/*max-width:unset !important;*/
	max-width: 500px;
	margin-bottom:30px
}

/* Login Section Form Wrapper */
.login__wrapper_form_wrapper {
  display: flex;
  flex: 1;
  height: 40vw;
  min-height:36.25rem;
  /*background-color: #2E0249;*/
  position:relative;
  border-radius:30px;
}

/* Login Section Form Wrapper */
.login__wrapper_form_wrapper.mobile {
  background-color: unset !important;
}

/* Login Section Concept Image Wrapper */
.login__wrapper_conceptImage_wrapper {
  flex: 1.5;
  height: 40vw;
 /* background-color: #2E0249;*/
  min-height:36.25rem;
 /* border-radius: 135px 0px 0px 0px;*/
  position: relative;
  display:flex;
  align-items: center;
  border-radius:60px;
}

/* Login Section Concept Image Wrapper */
.login__wrapper_conceptImage_wrapper.mobile {
    background-color: unset !important;
}

/* Login Section Concept Image  */
.login__wrapper_conceptImage_image {
  width: 100%;
  min-width: 50vw;
  /*max-width: 535px;*/
  max-height: 537px;
  height: auto;
  position: absolute;
  /*top: 15%;*/
  left: -25px;
  /*animation: 60s linear 0s normal none infinite running zoomin;
  -webkit-animation: 60s linear 0s normal none infinite running zoomin;
  -o-animation: 60s linear 0s normal none infinite running zoomin;
  -moz--o-animation: 60s linear 0s normal none infinite running zoomin;*/
}

/* Login Section Concept Image  */
.login__wrapper_conceptImage_image.mobile {
  position: unset;
/*  animation: 60s linear 0s normal none infinite running zoomin;
  -webkit-animation: 60s linear 0s normal none infinite running zoomin;
  -o-animation: 60s linear 0s normal none infinite running zoomin;
  -moz--o-animation: 60s linear 0s normal none infinite running zoomin;*/
}

@keyframes zoomin {
    0% { transform: scale(1, 1) }
    50% { transform: scale(1.2, 1.2) }
	100% { transform: scale(1, 1) }
}


/* Login Section Form Wrapper  */
.login__form_wrapper {
  flex: 1;
 /* border-radius: 0px 0px 135px 0px;*/
  /*background-color: #F9F9F9;*/
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: -1px;
  flex-direction:row;

}

/* Memovo Section Wrapper*/
.memovoSection__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  
  padding-bottom: 90px;
  padding-top: 90px;

  margin-top: 15px;
  margin-left:35px;
  margin-right:35px;
  margin-bottom:25px;
  border-radius:30px;
  background-color:#F2F4F7;
  

}


/* Memovo Section Wrapper Content Wrapper*/
.memovoSection__wrapper_content_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /*background-color: #2E0249;*/
  max-width: 1920px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Memovo Section Wrapper Content Wrapper*/
.memovoSection__wrapper_content_wrapper.mobile {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  background-color: #2E0249;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Memovo Section Content Image */
.memovoSection__wrapper_content_image {
  width: 100%;
  max-width: 745px;
  height: auto;
}

/* Memovo Section Content Text */
.memovoSection__wrapper_content_text {
  flex: 1;
  color: #1D2939;
  /*height: 308px;*/
  /*max-width: 650px;*/
  max-width: 80%;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 160.5%;
  letter-spacing: 0.1em;
  text-align: left;
  margin-left: 64px;
}

/* Memovo Section Content Header */
.memovoSection__wrapper_content_header {
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: #1D2939;
  margin-bottom: 15px;
  
}


/* Contato Section Wrapper*/
.contatoSection__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  padding-bottom: 60px;
  padding-top: 60px;
  margin-top: 15px;
  margin-left: 35px;
  margin-right: 35px;
  margin-bottom: 25px;
  border-radius: 30px;
  background-color: #F2F4F7;

}



/* Contato Section Wrapper Content Wrapper*/
.contatoSection__wrapper_content_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}


/* Contato Section Wrapper Content Wrapper*/
.contatoSection__wrapper_content_wrapper.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}


/* Contato Section Content Image */
.contatoSection__wrapper_content_image {
  width: 90%;
  max-width: 628px;
  height: auto;
}


/* Contato Section Content Text */
.contatoSection__wrapper_content_text {
  flex: 1;
  color: #1D2939;
  /*height: 335px;*/
  max-width: 400px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-align: left;
}

/* Contato Section Content Header */
.contatoSection__wrapper_content_header {
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: #1D2939;
  margin-bottom: 15px;
  text-align: left;
}

.passwordInput, .emailInput {
	width:100%;
	height:56px;
}

.passwordInput input {
	width:100%;
	height:56px;
}

.p-input-icon-right>i:last-of-type, .p-input-icon-right>svg:last-of-type, .p-input-icon-right>.p-input-suffix {
    color: #814DE5;
	font-weight:800;
}

.forgotPasswordButton {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
	text-align: center;
	letter-spacing: 0.1em;
	color:#814DE5;
	cursor:pointer;
}

.formTools {
	display:flex;
	flex-direction:row;
	gap:1.5rem;
}

.formActions {
	display:flex;
	flex-direction:column;
	margin-top:2rem;
}

.formTitle {
	margin: 0;
	margin-bottom: 1.25rem;
	font-size: 2.25rem;
	color: #373737;
	font-weight: 600;
	line-height: 120%;
	letter-spacing: 0.1em;
}

.formSubTitle {
	margin: 0;
	margin-bottom: 2.5rem;
	font-size: 1.25rem;
	color: #6B6B6B;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 0.1em;
}

.formWrapper {
	width: 100%;
	margin-right: 20%;
	max-width: 27.5rem;
	height: auto;
}

.contatoSection__wrapper_content_formTop_image {
	width: 8.625rem;
	height: auto;
	align-self: center;
	margin-bottom: 1.875rem;	
}

.globalLoading_image {
    width: 3rem;
    height: auto;
    align-self: center;
    margin-bottom: 1rem;
}

.login__wrapper_form_wrapper_cornerImage {
	width: 9.375rem;
	height: auto;
	position: absolute;
	right: -1px;
	bottom: -1px;	
}

.login__wrapper_conceptImage_wrapper_cornerImage {
	width: 150px;
	height: auto;
	position: absolute;
	left: -1px;
	top: -1px;	
} 

.contatoOptionText {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #1D2939;	
}


.serviceSelector:hover {
    border:1px solid #814DE5;
	opacity: 1 !important;
}

.serviceSelector {
    transition: all 0.3s ease-in-out,transform 1s ease-in-out;
	border:1px solid transparent;
	transform:translateX(300%);
	opacity:0;
}

.serviceTransferSelector {
    transition: all 1s ease-in-out;	
	opacity:0;
}

.serviceTransferSelector.animate {    
	opacity:1;
}


.selectBoxTitle {
    transition: all 1s ease-in-out;
	transform:translateX(50%);
	opacity:0;
}

.selectBoxTitle.animate {
	transform:translateX(0%);
	opacity:1;
}

/*
.serviceSelector.disabled,.serviceSelector.disabled:hover {    
	opacity: 0.5 !important;
	border:0px solid #FFF !important;
}*/

.serviceSelector:nth-child(1) {
	transform:translateX(300%);
}

.serviceSelector:nth-child(2) {
	transform:translateX(600%);
}

.serviceSelector:nth-child(3) {
	transform:translateX(900%);
}

.serviceSelector.animate {
    transform:translateX(0%);
	opacity:1;
}


/* Change all elements except the one with hover */
div.selectBox:hover > div.serviceSelector:not(:hover){
  opacity: 0.5 !important;
}


.serviceSelector:hover .svgFillElement {
    fill:#814DE5;
}

.serviceSelector .svgFillElement {
     transition: fill 0.3s ease-in-out;
}


.serviceSelector:hover .title {
	color:#814DE5 !important;
}

.serviceSelector .title {
     transition: all 0.3s ease-in-out;
}

/* Step component css START */
.p-steps .p-steps-item .p-menuitem-link {
    background: unset;
}

.p-steps-item {
    padding: 15px;
}

.p-steps .p-steps-item:before {
    border-top: 2px solid #814DE5;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #814DE5;
    color: #fff;
	border:2px solid #fff;
}


.p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 700;
    color: #814DE5;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 14px;
    /*color: #814DE5;*/
	
}

.p-steps li.p-steps-item.p-highlight.p-steps-current > a > span.p-steps-title {
	font-family: 'Titillium Web';
	font-style: bold;
	font-weight: 600;
	font-size: 13px;
	line-height: 120%;
	letter-spacing: 0.1em;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 120%;
	letter-spacing: 0.1em;
}


.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 120%;
	letter-spacing: 0.1em;
}
/* Step component css END */


.rightPanel {
    transition: all 0.5s ease-in-out;	
	transform:translateX(100%);
	opacity: 0 !important;
	display:none;
}

.rightPanel.animate {    
	transform:translateX(0%);
	opacity: 1 !important;
	display:block;
}

.leftPanel {
    transition: all 0.5s ease-in-out;	
	width:100%;
}

.leftPanel.animate {    
	width:66.66%;
}

.p-tabview-selected {
	border: 2px solid #814DE5;
	border-bottom:0px;
    border-radius: 6px 6px 0px 0px;
}

.timeBar.animate {
	animation: pulse 2s infinite;
	
}

.animate.p-progressbar .p-progressbar-value {
	background-color:red !important;	
}

@keyframes pulse {
	0% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.p-tabview .p-tabview-panels {
    padding:0 !important;
}

.p-overlaypanel .p-overlaypanel-content {
    width:100%;
}


button.buttonLeftPanel span {
	flex:unset !important;
}

.paid span.p-inputswitch-slider {
    background-color: green !important;
}

.navigationItem:hover {
	transition: all 0.3s ease-in-out;
	opacity: 0.7 !important;
}

.navigationItem {
    transition: all 0.3s ease-in-out,transform 1s ease-in-out;
	transform:translateY(300%);
	opacity:0;
}

.navigationItem.animate {
	transform:translateY(0%);
	opacity:1;
}
 
/* Estilos adicionais para a animação sequencial */
.navigationItem:nth-child(1) {
	transition-delay: 0s;
  } 
  
  .navigationItem:nth-child(2) {
	transition-delay: 0.1s;
  }
  
  .navigationItem:nth-child(3) {
	transition-delay: 0.2s;
  } 
  
  .navigationItem:nth-child(4) {
	transition-delay: 0.3s;
  }
  
  .navigationItem:nth-child(5) {
	transition-delay: 0.4s;
  }
  
  .navigationItem:nth-child(6) {
	transition-delay: 0.5s;
  }

  .navigationItem:nth-child(7) {
	transition-delay: 0.6s;
  }

  .navigationItem:nth-child(8) {
	transition-delay: 0.7s;
  }

  .navigationItem:nth-child(9) {
	transition-delay: 0.8s;
  }

  .navigationItem:nth-child(10) {
	transition-delay: 0.9s;
  }

  .navigationItem:nth-child(11) {
	transition-delay: 1s;
  }

  .navigationItem:nth-child(12) {
	transition-delay: 1.1s;
  }

  .navigationItem:nth-child(13) {
	transition-delay: 1.2s;
  }

  .navigationItem:nth-child(14) {
	transition-delay: 1.3s;
  }

  .navigationItem:nth-child(15) {
	transition-delay: 1.4s;
  }

  .navigationItem:nth-child(16) {
	transition-delay: 1.5s;
  }



  .navigationButton.current {
	border:1px solid #00A3FF !important;
	background: #00A3FF !important;
	color:#FFF !important;
  }

  .navigationButton.previous {
	border:1px solid #814DE5 !important;
	background: #814DE5 !important;
	color:#FFF !important;
  }
  
  .navigationButton.next {
	border:1px solid #814DE5 !important;
	background: unset !important;
  }


  .breadcrumbSelectedLabel {
	color: #814DE5;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px; /* 162.5% */
	letter-spacing: 0.46px;
  }

  .breadcrumbCurrentLabel {
	color: #00A3FF;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.46px;
  }

  .p-breadcrumb {
    background: unset !important;
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
}

  .p-breadcrumb ul > li:nth-child(1) {
    display:none !important;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
    font-size:0.7rem !important;
    color: #814DE5;
}

.resultsCard_image .imagePlusIcon {
	filter: drop-shadow(5px 5px 10px #000000);
    color: #FFFFFF;
    font-size: 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease; /* Adiciona uma transição suave */
}

.resultsCard_image:hover .imagePlusIcon {
    opacity: 0.7;
}

.filterMapImage .addressPlusIcon {
	filter: drop-shadow(5px 5px 10px #000000);
    color: #FFFFFF;
    font-size: 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease; /* Adiciona uma transição suave */
}

.filterMapImage:hover .addressPlusIcon {
    opacity: 0.7;
}



.galleryDialog .p-dialog-content {
	padding:0.5rem;
	padding-bottom:0;
	padding-top:0;
} 

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background:unset !important;
}

.imageGallery .imageGallery_item:hover {
	border:2px solid gray;
}


.imageGallery .imageGallery_item.selected {
	border:2px solid #814DE5;
}

.p-galleria-item-nav {
    transform: translateY(-50%) !important;
}

.updateButton span.p-button-label, .backButton span.p-button-label {
	color:#814DE5 !important;
	font-weight: 500 !important;
}

.customTab.p-tabview-selected {
    border: unset !important;
}

.customTab .p-tabview-panels {
	background-color:transparent !important;	
}

.customTab ul {
	background:transparent !important;
	border:unset !important;
}

.cardPanel {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
  }
  
.cardPanel.fade-in {
	opacity: 1;
	display:block;
}
  
.cardPanel.fade-out {
	display:none;
	opacity: 0;
}

.highIndex {
	z-index:999 !important;
}

.sticky-element {

	padding:10px;
	position:sticky;
	top:70px;
	background-color:#FFF;
	box-shadow: 1px 10px 10px #37373722;
	z-index:9;

}

.titleBox {
    transition: background 0.5s ease-in-out,box-shadow 0.5s ease-in-out;
}

.searchInput input {
	width:100% !important;
}


.f54c1d70e0 {
    position: absolute;
	padding:10px;
    top: 0px;
    left: 1px;
    width: auto;
    height: auto;
    background: #008234;
    border-radius: 1px 1px 5px 0px;
    box-shadow: 0 2px 8px 0 rgba(26, 26, 26, 0.16);
    z-index: 5;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    color:#FFFFFF;
}


.ribbon {
	font-size: 12px;
	font-weight: bold;
	color: #fff;
  }

  .ribbon {
	--r: .5em; /* control the ribbon shape (the radius) */
	--c: #008234;
   
	z-index: 5;
	position: absolute;
	top: 20px;
	left: calc(-1*var(--r));
	line-height: 3.5; 
	padding: 0 1em calc(2*var(--r));
	filter: drop-shadow(5px 5px 8px #00000055);
	border-radius: var(--r) 0 0 var(--r);
	background: 
	  radial-gradient(100% 50% at left,var(--c) 98%,#0000 101%)
	   100% 0/.5lh calc(100% - 2*var(--r)),
	  radial-gradient(100% 50% at right,#0005 98%,#0000 101%) 
	   0 100%/var(--r) calc(2*var(--r)),
	  conic-gradient(from 90deg at var(--r) calc(100% - 2*var(--r)),#0000 25%,var(--c) 0)
	   0 0/calc(101% - .5lh) 100%;
	background-repeat: no-repeat;   
  }

  .p-galleria-content {
    flex: 0.85 !important;
}
