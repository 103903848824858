/* src/components/ImageCard.css */
.image-card {
    border-radius: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /*animation: moveImage 10s infinite;*/
    height: 27.5vh;        
    overflow:hidden;
}

.image-card-image {
    background-size:cover;
    background-position: center;
    animation: moveImage 15s infinite alternate ease-in-out;
}


@keyframes moveImage {
    0% {
        transform: scale(1);
        
    }
    100% {
        transform: scale(1.1);        
    }
}
