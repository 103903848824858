.room-selector {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.room {
    margin-bottom: 20px;
   /* padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;*/
}

.room-title {

    color: var(--Light-Text-Primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    text-align:center;

}

.room-controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap:24px;
}

.room-each-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
}

.room-controls h4 {
    margin: 0;
    font-size: 16px;
}

.room-controls .control-buttons {
    display: flex;
    align-items: center;
}

.room-controls .control-buttons button {
    margin: 0 5px;
    width:2rem;
    height:2rem;
    border-radius:3.75rem;
}

.room-controls .control-buttons span.quantity {
    margin-left:1rem;       
    margin-right:1rem;       

    color: var(--Light-Text-Primary, rgba(0, 0, 0, 0.87));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.00938rem;

    min-width: 30px;

}


.child-controls-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.child-controls {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.child-controls .p-dropdown {
    margin-right: 10px;
}

.child-controls .p-button-danger {
    background-color: #e74c3c;
    border-color: #e74c3c;
}

.add-room-btn,
.confirm-btn {
    display: block;
    width: 100%;
    margin-top: 10px;
    background-color: #6a1b9a;
    border-color: #6a1b9a;
    color: #fff;
}

.add-room-btn:hover,
.confirm-btn:hover {
    background-color: #4a148c;
    border-color: #4a148c;
}

.add-room-btn:focus,
.confirm-btn:focus {
    box-shadow: 0 0 0 0.2rem rgba(106, 27, 154, 0.5);
}
