/* src/components/ImageGrid.css */
.image-grid {
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-height: 55vh;
    margin: 20px auto;
    justify-content: flex-end;
}

.image-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.image-column1 {
    width:16vw;
}

.image-column2 {
    width:23vw;
}


.image-one {
flex:1.5;
overflow: hidden;
border-radius: 40px;
}


.image-two {
flex:1;    
overflow: hidden;
border-radius: 40px;
}


.image-three {
flex:1;    
overflow: hidden;
border-radius: 40px;
}

.image-four {
flex:1.5;    
overflow: hidden;
border-radius: 40px;
}

