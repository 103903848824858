/* src/components/HotelCards.css */
.hotel-cards {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    max-width: 45vw;
    width:100%;
    margin-left: 135px;
    max-height:540px;
}

.image-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.image-row {
    display: flex;
    width: 100%;
    gap: 20px;
}

.image-row:first-child > .image-card:first-child {
    flex: 3;
}

.image-row:first-child > .image-card:last-child {
    flex: 1;
}

.image-row:last-child > .image-card {
    flex: 1;
}


.info-cards {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    z-index: 1;
    left:-7%;
}
